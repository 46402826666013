<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :noticeList="dataList"
         @scrollList="scrollList"
        @checkNoticeDetail="checkNoticeDetail"></Horizontal>
        <Vertical ref="Vertical" v-else 
        :noticeList="dataList" 
         @scrollList="scrollList"
        @checkNoticeDetail="checkNoticeDetail"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/GameNotice/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/GameNotice/Vertical/Index.vue'

    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                currentPage:1,//当前页
                limit:10,
                dataList: []
            }
        },
        created() {
            this.getList()
        },
        methods: {
            //获取游戏订单列表
            getList() {
                let requestData = {
                    user_id:this.$store.state.userId,
                    token:this.$store.state.token,
                    page:this.currentPage,
                    limit:this.limit
                }
                this.$api.game.getGameNoticeList(requestData).then(res=> {
                    if (res.data.code === 200) {
                        this.dataList = this.dataList.concat(res.data.data)
                    }
                })
            },
            //滚动列表触发
            scrollList(e) {
                    this.$common.pagingRequest(e, this)
            },
            checkNoticeDetail(item) {
                this.$router.push({
                    name: 'GameNoticeDetail',
                    params: {
                        item: item
                    }
                })
            }
        }
    }
</script>

<style>
</style>
