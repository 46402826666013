<template>
    <div class="all-page">
       <TopNav title="游戏公告" backPath="Mine"></TopNav>
       <div class="all-page-content-box" @scroll="scrollList" ref="scrollBox" v-if="noticeList.length>0">
           <div class="notice-item-box" v-for="(item, index) in noticeList" :key="index" @click="checkNoticeDetail(item)">
              <div class="notice-item">
                  <div class="title-text">
                      {{item.title}}
                  </div>
                  <div class="content-text" v-html="getHtmlText(item.desc)">
                  </div>
              </div>
           </div>
       </div>
        <BlankList v-else type="notice"></BlankList>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    import BlankList from "@/components/Common/Vertical/BlankList.vue"
    export default {
        components: {
          TopNav,
          BlankList
        },
        props:{
          noticeList:Array
        },
        data() {
            return {
                
            }
        },
        computed:{
          getHtmlText() {
              return function (e) {
                  let content = ''
                    content = e.replace('<img', "<img style='display: none;'")
                    return content
              }
              
          }  
        },
        methods: {
            scrollList(e) {
                this.$nextTick(()=> {
                    this.$emit('scrollList', this.$refs.scrollBox)
                })
            },
           checkNoticeDetail(item) {
               this.$emit('checkNoticeDetail', item)
           }
        }
    }
</script>

<style scoped>
    .notice-item-box {
        padding-bottom: 12px;
        width: 100%;
    }
    .notice-item {
        padding: 10px 18px 10px 10px;
        background: #F4F4F4;
        border-radius: 10px;
        width: 100%;
        height: 85px;
    }
    .title-text {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
    }
    .content-text {
        width: 80%;
        padding-top: 8px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        overflow: hidden;
        display:-webkit-box;
         text-overflow: ellipsis;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-break: break-all;
        /* 解决字母不换行问题 */
        word-break: break-all;
        /* 解决中文标点不换行的问题 */
        line-break: anywhere;
    }
</style>
